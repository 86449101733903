export const CLAIM_STATUSES = {
  APPROVED: 'approved',
  INCOMPLETE: 'incomplete',
  PENDING: 'pending',
  CLOSED: 'closed',
}

export const PROOF_STATUSES = {
  ACCEPTED: 'accepté',
  CHECKING: 'en vérification',
  REFUSED: 'refusé'
}

const CGU = 'https://www.get-owen.com/cgu'
const dataProtection = 'http://www.get-owen.com/politique-de-confidentialite'

const cguLink = `<a href=${CGU} target="_blank" rel="noopener noreferrer">CGU</a>`

const dataProtectionLink = `<a href=${dataProtection} target="_blank" rel="noopener noreferrer">politique de confidentialité des données</a>`

export const LEGAL_NOTICES =  `Owen SAS au capital de 16 112€, inscrite au RCS d’Evry sous le numéro 852 449 131 Immatriculée au Registre ORIAS, sous le numéro 19005949. Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits, reportez-vous à nos ${cguLink} et notre ${dataProtectionLink}. Dans le cadre de l’offre de souscription/adhésion aux assurance(s) facultative(s) accessoires, vos données personnelles sont traitées par votre Assureur, Mutuaide Assistance, responsable de traitement. Vous trouverez les coordonnées de votre Assureur sur les documents contractuels et précontractuels qui vous ont été remis ou mis à votre disposition. Vous disposez d’un droit d’accès, de rectification, d’opposition, d’effacement, de limitation et de demander la portabilité de certaines données personnelles vous concernant, que vous pouvez exercer auprès du Délégué à la Protection des données à l’adresse à l’adresse DRPO@MUTUAIDE.fr ou par courrier : en écrivant à l’adresse suivante : Délégué représentant à la protection des données – MUTUAIDE ASSISTANCE – 8/14 Avenue des Frères Lumière – 94368 Bry-sur-Marne. Vous disposez également de la possibilité de saisir l’autorité de contrôle compétente pour la protection des données en France, à savoir, la CNIL, en cas de manquements concernant la gestion de vos données personnelles. Les informations complémentaires sur vos droits et le traitement de vos données personnelles sont disponibles dans la Notice d’Information qui vous a été remise lors de votre souscription. Les informations complémentaires sur vos droits et le traitement de vos données personnelles sont disponibles sur le site de votre Assureur sous l’onglet « Vie privée » ainsi que dans les Conditions Générales ou Notices d’Information qui vous ont été remises ou mise à votre disposition lors de votre souscription.`