import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useNavigate, useOutletContext } from 'react-router-dom'

import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import style from './ContactMainForm.module.scss'
import PageTitle from 'components/common/PageTitle/PageTitle'
import Lock from 'components/assets/icons/Lock'
import Datepicker from 'components/common/Datepicker/Datepicker'
import { isInputError } from 'helpers/form'
import api from 'helpers/api'
import InfoCircle from 'components/assets/icons/InfoCircle'
import InfoMessage from 'components/common/InfoMessage/InfoMessage'
import Select from 'components/common/Select/Select'

const ContactMainForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const client = useOutletContext()

  let inputTimeout

  const getDefaultValue = field => {
    return client?.[field] || ''
  }

  const birthdateOutput = new Date(client?.birthdate)

  const {
    control,
    getValues,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    trigger,
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    values: {
      firstname: getDefaultValue('firstname'),
      lastname: getDefaultValue('lastname'),
      birthdate: isNaN(birthdateOutput) ? new Date() : birthdateOutput,
      placeOfBirth: getDefaultValue('placeOfBirth'),
      countryOfBirth: getDefaultValue('countryOfBirth'),
      address: getDefaultValue('address'),
      zipcode: getDefaultValue('zipcode'),
      city: getDefaultValue('city'),
      country: getDefaultValue('country'),
      additionalAddress: getDefaultValue('additionalAddress'),
      email: getDefaultValue('email'),
      phone: getDefaultValue('phone'),
    },
    mode: 'onTouched',
  })

  const errorFields = Object.keys(errors)

  const onSubmit = handleSubmit(async client => {
    try {
      const clientRes = await api.put({
        url: '/clients/me',
        data: { ...client },
      })

      navigate('/contacts', { state: { client: clientRes.response?.client } })
    } catch (error) {
      console.log('Update client contacts error')
    }
  })

  const onZipCodeChange = async value => {
    const res = await api.get({ url: `/clients/address/${value}` })

    const city = res.address_components.find(item => item.types.includes('locality') || item.types.includes('postal_town'))?.long_name || ''
    const zipcode = res.address_components.find(item => item.types.includes('postal_code'))?.long_name || ''
    const country = res.address_components.find(item => item.types.includes('country'))?.long_name || ''

    if (zipcode) setValue('zipcode', zipcode, true)
    if (city) setValue('city', city, true)
    if (country) setValue('country', country, true)

    trigger()
  }

  const getAddressOptions = async address => {

    if (address) {
      const res = await api.get({
        url: '/clients/address',
        params: { address },
      })

      const options = res.map(opt => ({
        label: opt.description,
        value: opt.place_id
      }))

      return options
    } else {
      return []
    }
  }

  const loadOptions = val => {
    clearTimeout(inputTimeout)
    return new Promise(resolve => {
      inputTimeout = setTimeout(async () => {
        const data = await getAddressOptions(val)
        resolve(data)
      }, 1500)
    })
  }

  if (!client) return null

  return (
    <>
      <PageTitle text={t('main:clientContacts.summary.form.title')} />
      <InfoMessage
        className={style['info-message']}
        label={t('main:clientContacts.summary.form.infoMessage')}
        prefix={<InfoCircle />}
      />

      <form className={style.form} onSubmit={onSubmit}>
        <div className={style.inputs}>
          <Input
            className={style.input}
            error={isInputError(errorFields, 'firstname')}
            register={register('firstname', { required: true })}
            label={t('common:form.firstname.label')}
            placeholder={`${t('common:your')} ${t('common:form.firstname.label').toLowerCase()}`}
            readOnly
            suffix={<Lock />}
          />
          <Input
            className={style.input}
            error={isInputError(errorFields, 'lastname')}
            register={register('lastname', { required: true })}
            label={t('common:form.lastname.label')}
            placeholder={`${t('common:your')} ${t('common:form.lastname.label').toLowerCase()}`}
            readOnly
            suffix={<Lock />}
          />
          <div className={classNames(style.datepicker, style.input)}>
            <div className="input-label">{t('common:form.birthdate.label')}</div>
            <Controller
              control={control}
              name="birthdate"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Datepicker
                  className={style.input}
                  error={isInputError(errorFields, 'birthdate')}
                  onChange={onChange}
                  placeholder={t('common:form.birthdate.placeholder')}
                  value={value}
                />
              )}
            />
          </div>
          <Input
            className={style.input}
            error={isInputError(errorFields, 'placeOfBirth')}
            register={register('placeOfBirth', { required: true })}
            label={t('common:form.placeOfBirth.label')}
            placeholder={`${t('common:your')} ${t('common:form.placeOfBirth.label').toLowerCase()}`}
          />
          <Input
            className={style.input}
            error={isInputError(errorFields, 'countryOfBirth')}
            register={register('countryOfBirth', { required: true })}
            label={t('common:form.countryOfBirth.label')}
            placeholder={`${t('common:your')} ${t('common:form.countryOfBirth.label').toLowerCase()}`}
          />
          <Input
            className={style.input}
            error={isInputError(errorFields, 'email')}
            register={register('email', { required: true })}
            label={t('common:form.email.label')}
            placeholder={`${t('common:your')} ${t('common:form.email.placeholder')}`}
            readOnly
            suffix={<Lock />}
          />
          <Input
            className={style.input}
            error={isInputError(errorFields, 'address')}
            register={register('address', { required: true })}
            label={t('common:form.address.label')}
            placeholder={`${t('common:your')} ${t('common:form.address.label').toLowerCase()}`}
          />
          <div className={style.input}>
            <div className="input-label">{t('common:form.zipCode.label')}</div>
            <Controller
              control={control}
              name="zipcode"
              rules={{ required: true }}
              render={() => (
                <Select
                  async
                  cacheOptions
                  className={classNames(style.select, style.input)}
                  defaultValue={{ label: getValues('zipcode'), value: getValues('zipcode') }}
                  error={isInputError(errorFields, 'zipcode')}
                  isSearchable
                  loadOptions={loadOptions}
                  onChange={opt => onZipCodeChange(opt.value)}
                  placeholder={`${t('common:your')} ${t('common:form.zipCode.label').toLowerCase()}`}
                  styles={{ indicatorContainer: { paddingRight: 8 } }}
                  value={getValues('zipcode')}
                />
              )}
            />
          </div>
          <Input
            className={style.input}
            error={isInputError(errorFields, 'city')}
            register={register('city', { required: true })}
            label={t('common:form.city.label')}
            placeholder={t('common:form.city.placeholder')}
          />
          <Input
            className={style.input}
            error={isInputError(errorFields, 'country')}
            register={register('country', { required: true })}
            label={t('common:form.country.label')}
            placeholder={`${t('common:your')} ${t('common:form.country.label').toLowerCase()}`}
          />
          <Input
            className={style.input}
            error={isInputError(errorFields, 'additionalAddress')}
            register={register('additionalAddress')}
            label={t('common:form.additionalAddress.label')}
            placeholder={t('common:form.additionalAddress.placeholder')}
          />
          <Input
            className={style.input}
            error={isInputError(errorFields, 'phone')}
            register={register('phone', { required: true })}
            label={t('common:form.phone.label')}
            placeholder={`${t('common:your')} ${t('common:form.phone.placeholder').toLowerCase()}`}
          />
        </div>

        <Button
          className={style.button}
          label={t('common:save')}
          onClick={handleSubmit}
          type="primary"
          disabled={!isValid}
        />
      </form>
    </>
  )
}

export default ContactMainForm
