import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import dayjs from 'dayjs'

import PageTitle from 'components/common/PageTitle/PageTitle'
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle'
import style from './ClaimItem.module.scss'
import Arrow from 'components/assets/icons/Arrow'
import DocumentText from 'components/assets/icons/DocumentText'
import Card from 'components/common/Card/Card'
import Button from 'components/common/Button/Button'
import Clock from 'components/assets/icons/Clock'
import Status from 'components/common/Status/Status'
import CardLine from 'components/common/CardLine/CardLine'
import Thundercloud from 'components/assets/icons/Thundercloud'
import Collapsible from 'components/common/Collapsible/Collapsible'
import CircleOk from 'components/assets/icons/CircleOk'
import api from 'helpers/api'
import Download from 'components/assets/icons/Download'
import Eye from 'components/assets/icons/Eye'
import { downloadDocument, getFileUrl } from 'helpers/files'
import Link from 'components/common/Link/Link'
import ExclamationTriangle from 'components/assets/icons/ExclamationTriangle'
import { CLAIM_STATUSES, PROOF_STATUSES } from 'constants/contract'
import InfoMessage from 'components/common/InfoMessage/InfoMessage'

const ClaimItem = () => {
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const inputRef = useRef([])
  const [claim, setClaim] = useState(null)
  const [areCommentsOpened, setAreCommentsOpened] = useState(false)
  const [error, setError] = useState(null)
  const isVideoSelected = !!claim?.document?.find( doc => doc.isVideo)
  const clientDocuments = claim?.documents.sort( (a, b) => a.createdAt < b.createdAt ? 1 : -1)
  const { APPROVED, INCOMPLETE, CLOSED } = CLAIM_STATUSES
  const { ACCEPTED, CHECKING, REFUSED } = PROOF_STATUSES

  useEffect(() => {
    const getClaim = async () => {
      try {
        const res = await api.get({ url: `/claims/${params.id}` })
        setClaim(res)
      } catch (error) {
        navigate('/claims')
      }
    }
    getClaim()
  }, [params.id, navigate])


  const getDocumentUrl = useCallback(
    async document => {
      const url = await getFileUrl({
        claimId: claim._id,
        contractId: claim.adhesionId,
        documentId: document._id
      })
      window.open(url, '_blank').focus()
    },
    [claim],
  )


  const displayError = (msg) => {
    window.scrollTo(0, 0)
    setError(msg)
    setTimeout(() => setError(null), 5000)
  }

  const onFilesSubmit = async (event, index) => {
    let file = null
    const item = event.target.files[0]

    if (item.size > process.env.REACT_APP_FILESIZE_LIMIT){
      displayError(t('claim:item.fileTooLarge'))
    } else if (item.type.startsWith('video/') && isVideoSelected){
      displayError(t('claim:item.limitVideoQuantity'))
    } else if (!item.type.match(/image|pdf|video/i)) {
      displayError(t('claim:item.fileFormatForbidden'))
    } else {
      item.proofOfClaimsId = claim.nature.proofOfClaims[index]._id
      file = item
    }

    try {
      if (file) {
        const formData = new FormData()
        const mixedName = JSON.stringify({
          name: file.name,
          proofOfClaimsId: file.proofOfClaimsId
        })
        formData.append('files', file, mixedName)

        const res = await api.post({
          url: `/claims/${claim._id}/documents`,
          contentType: 'multipart/form-data',
          data: formData,
        })

        const { documents, status } = res.response.claim
        setClaim({ ...claim, documents, status })

      }
    } catch (error) {
      displayError(t('common:form.genericError'))
    }
  }

  const handleUploadClick = index => {
    if (inputRef.current[index]) {
      inputRef.current[index].click()
    }
  }


  const sortedComments = useMemo(
    () => (claim?.commentsClaims || []).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
    [claim?.commentsClaims],
  )
  const [lastComment, ...comments] = sortedComments

  const Comment = ({ item }) => {
    return (
      <div className={style.comment}>
        <CardLine
          className="capitalized"
          title={dayjs(item.createdAt).locale('fr').format('DD.MM.YYYY')}
          styles={{ marginBottom: 0 }}
        />
        <CardLine content={item.commentsDashboard} styles={{ marginBottom: 0 }} />
      </div>
    )
  }


  const StatusBlock = () => (
    <ClaimCard
      icon={
        claim.status === APPROVED
        ? <CircleOk />
        : [CLOSED, INCOMPLETE].includes(claim.status)
          ? <ExclamationTriangle />
          : <Clock />
      }
      content={
        <>
          <p className={classNames(style['last-comment'], style.light)}>
            {lastComment
              ? lastComment.commentsDashboard
              : claim.status === INCOMPLETE
                ? t('claim:item.missingDocuments')
                : t('claim:item.defaultComment')
            }
          </p>

          {!!comments.length && (
            <>
              <div className={style.comments}>
                {areCommentsOpened && comments.map((item, index) => (
                  <Comment item={item} key={`claim-comment-${index}`} />
                ))}
              </div>
              <Link
                className={style['comments-link']}
                label={`${areCommentsOpened ? t('common:hide') : t('common:see')} ${t('common:history')}`}
                onClick={() => setAreCommentsOpened(!areCommentsOpened)}
              />
            </>
          )}
        </>
      }
    />
  )


  const ClaimCard = ({ content, icon }) => {
    const { status } = claim
    const type = status === APPROVED ? 'success' : status === INCOMPLETE || status === CLOSED ? 'error' : 'warning'
    const updatedAt = lastComment?.createdAt || claim.updatedAt
    const statusDate = dayjs(updatedAt).locale('it').format('DD.MM.YYYY');

    return (
      <Card
        className={classNames(style.card, style.status)}
        content={content}
        title={<span className={classNames(style['status-date'], 'capitalized')}>{statusDate}</span>}
        titleIcon={icon}
        cornerContent={<Status label={t(`claim:item.status.${status}`)} type={type} />}
        type={type}
      />
    )
  }


  // Required documents status

  const DocAcceped = ({file}) => (
    <div>
      <Link
        className={style['document-link']}
        label={<Download />}
        onClick={() => downloadDocument({
          claim,
          contract: { _id: claim.adhesionId },
          documentItem: {...file, name: file.originalName || file.name}
        })}
      />
      <Link
        className={style['document-link']}
        label={<Eye className={style.eye} />}
        onClick={() => getDocumentUrl(file)}
        target="_blank"
        rel="noopener noreferrer"
      />
    </div>
  )

  const UploadDoc = ({index}) => (
    <div style={{cursor:'pointer'}} onClick={() => handleUploadClick(index)}>
      <Download />
      {t('common:upload')}
      <input
        type="file"
        accept={'image/*, .pdf' + (!isVideoSelected ? ', video/*' : '')}
        ref={el => (inputRef.current[index] = el)}
        onChange={e => onFilesSubmit(e, index)}
        hidden
      />
    </div>
  )

  const CheckingDoc = () => (
    <div>
      <Clock />{t(`claim:item.status.checking`)}
    </div>
  )



  return claim && (
    <>
      <PageTitle text={t('claim:item.title')} />
      <PageSubtitle text={t('claim:item.subtitle')} />

      {error && <InfoMessage
        className={style.infoMessage}
        type='error'
        label={error}
        prefix={<ExclamationTriangle />}
      />}

      <StatusBlock />
      <Card
        className={style.card}
        content={claim.description}
        onClick={() => navigate(`/contracts/${claim.adhesionId}`)}
        title={claim.name}
        titleIcon={<DocumentText />}
        cornerContent={<Arrow />}
      />

      <Collapsible
        title={
          <>
            <Thundercloud styles={{ fill: '#1414FF' }} />
            <div className={style['collapsible-title']}>#{claim.shortId}</div>
          </>
        }
        isOpenedProp={true}
        content={
          <>
            <div className={style['summary-first-line']}>
              <CardLine
                className={style['first-line-element']}
                title={t('claim:item.date')}
                content={dayjs(claim.createdAt).format('DD/MM/YYYY')}
              />
              <CardLine
                className={style['first-line-element']}
                title={t('claim:item.location')}
                content={claim.location}
              />
              <CardLine
                className={style['first-line-element']}
                title={t('claim:item.nature')}
                content={claim.nature?.name || t('common:other')}
              />
            </div>
            <CardLine
              className={style.circumstances}
              title={t('claim:item.circumstances')}
              content={claim.circumstance}
            />

            {!!claim.nature.proofOfClaims?.length &&
              <CardLine
                className={style['required-documents']}
                title={<>
                  {t('claim:applySteps.documents.requiredDocuments')}
                  <br/>
                  {t('claim:applySteps.documents.contact')}
                </>}
                content={
                  <ul>
                    {claim.nature.proofOfClaims.map( (proof, index) => {
                      const file = clientDocuments.find( elm => elm.proofOfClaimsId === proof._id )
                      const checking = file?.status === CHECKING
                      const accepted = file?.status === ACCEPTED
                      const rejected = file?.status === REFUSED
                      return (
                        <li className={rejected ? style.rejected : checking ? style.checking : ''} key={`document-${index}`}>
                          <legend>
                            {(!file || rejected) && <ExclamationTriangle />}
                            {proof.name}
                          </legend>
                          {(!file || rejected) && <UploadDoc index={index} />}
                          { checking && <CheckingDoc /> }
                          { accepted && <DocAcceped file={file} /> }
                        </li>
                      )
                    })}
                  </ul>
                }
              />
            }
          </>
        }
      />

      <Button
        className={style.button}
        label={t('claim:item.myClaimsButton')}
        onClick={() => navigate('/claims')}
        type="secondary"
      />
    </>
  )
}

export default ClaimItem
