import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'

import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import style from './ContactForm.module.scss'
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle'
import PageTitle from 'components/common/PageTitle/PageTitle'
import Card from 'components/common/Card/Card'
import DocumentText from 'components/assets/icons/DocumentText'
import Arrow from 'components/assets/icons/Arrow'
import Lock from 'components/assets/icons/Lock'
import Datepicker from 'components/common/Datepicker/Datepicker'
import { isInputError } from 'helpers/form'

const ContactForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()

  const { contract, isSomeoneElse } = state || {}

  const getDefaultValue = field => (!isSomeoneElse && contract && contract[field]) || ''
  const defaultBirthdate = new Date(getDefaultValue('birthdate'))

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    defaultValues: {
      clientRelationship: '',
      firstname: getDefaultValue('firstname'),
      lastname: getDefaultValue('lastname'),
      birthdate: isNaN(defaultBirthdate) ? new Date() : defaultBirthdate,
      placeOfBirth: getDefaultValue('placeOfBirth'),
      countryOfBirth: getDefaultValue('countryOfBirth'),
      address: getDefaultValue('address'),
      zipcode: getDefaultValue('zipcode'),
      city: getDefaultValue('city'),
      country: getDefaultValue('country'),
      additionalAddress: getDefaultValue('additionalAddress'),
      email: getDefaultValue('email'),
      phone: getDefaultValue('phone'),
    },
    mode: 'onTouched',
  })

  const errorFields = Object.keys(errors)

  const onSubmit = handleSubmit(async data => {
    navigate('../circumstances', {
      state: {
        contract,
        step: 2,
        claim: { ...data, notPersonnalClaimDeclaration: !!isSomeoneElse },
        fromClaim: true,
      },
    })
  })


  return !contract
  ? <Navigate to={'/claims/new'} replace />
  : (
    <>
      <PageTitle
        text={
          isSomeoneElse ? t('claim:applySteps.contacts.someoneElse.title') : t('claim:applySteps.contacts.you.title')
        }
      />
      <PageSubtitle
        text={
          isSomeoneElse
            ? t('claim:applySteps.contacts.someoneElse.subtitle')
            : t('claim:applySteps.contacts.you.subtitle')
        }
      />

      <form className={style.form} onSubmit={onSubmit}>
        <div className={style.inputs}>
          {isSomeoneElse && (
            <Input
              className={style.input}
              register={register('clientRelationship', { required: true })}
              label={t('claim:applySteps.contacts.someoneElse.clientRelationship.label')}
              placeholder={t('claim:applySteps.contacts.someoneElse.clientRelationship.placeholder')}
            />
          )}
          <Input
            className={style.input}
            error={isInputError(errorFields, 'firstname')}
            register={register('firstname', { required: true })}
            label={t('common:form.firstname.label')}
            placeholder={`${t(isSomeoneElse ? 'common:their' : 'common:your')} ${t(
              'common:form.firstname.label',
            ).toLowerCase()}`}
            readOnly={!isSomeoneElse}
            suffix={!isSomeoneElse && <Lock />}
          />
          <Input
            className={style.input}
            error={isInputError(errorFields, 'lastname')}
            register={register('lastname', { required: true })}
            label={t('common:form.lastname.label')}
            placeholder={`${t(isSomeoneElse ? 'common:their' : 'common:your')} ${t(
              'common:form.lastname.label',
            ).toLowerCase()}`}
            readOnly={!isSomeoneElse}
            suffix={!isSomeoneElse && <Lock />}
          />
          <div className={classNames(style.datepicker, style.input)}>
            <div className="input-label">{t('common:form.birthdate.label')}</div>
            <Controller
              control={control}
              name="birthdate"
              render={({ field: { onChange, value } }) => (
                <Datepicker
                  className={style.input}
                  error={isInputError(errorFields, 'birthdate')}
                  onChange={onChange}
                  placeholder={t(
                    isSomeoneElse ? 'common:form.birthdate.placeholder2' : 'common:form.birthdate.placeholder',
                  )}
                  register={register('birthdate', { required: true })}
                  value={value}
                />
              )}
            />
          </div>
          <Input
            className={style.input}
            error={isInputError(errorFields, 'placeOfBirth')}
            register={register('placeOfBirth', { required: true })}
            label={t('common:form.placeOfBirth.label')}
            placeholder={`${t(isSomeoneElse ? 'common:their' : 'common:your')} ${t(
              'common:form.placeOfBirth.label',
            ).toLowerCase()}`}
          />
          <Input
            className={style.input}
            error={isInputError(errorFields, 'countryOfBirth')}
            register={register('countryOfBirth', { required: true })}
            label={t('common:form.countryOfBirth.label')}
            placeholder={`${t(isSomeoneElse ? 'common:their' : 'common:your')} ${t(
              'common:form.countryOfBirth.label',
            ).toLowerCase()}`}
          />
          <Input
            className={style.input}
            error={isInputError(errorFields, 'address')}
            register={register('address', { required: true })}
            label={t('common:form.address.label')}
            placeholder={`${t(isSomeoneElse ? 'common:their' : 'common:your')} ${t(
              'common:form.address.label',
            ).toLowerCase()}`}
          />
          <Input
            className={style.input}
            error={isInputError(errorFields, 'zipcode')}
            register={register('zipcode', { required: true })}
            label={t('common:form.zipCode.label')}
            placeholder={`${t(isSomeoneElse ? 'common:their' : 'common:your')} ${t(
              'common:form.zipCode.label',
            ).toLowerCase()}`}
          />
          <Input
            className={style.input}
            error={isInputError(errorFields, 'city')}
            register={register('city', { required: true })}
            label={t('common:form.city.label')}
            placeholder={t(isSomeoneElse ? 'common:form.city.placeholder2' : 'common:form.city.placeholder')}
          />
          <Input
            className={style.input}
            error={isInputError(errorFields, 'country')}
            register={register('country', { required: true })}
            label={t('common:form.country.label')}
            placeholder={`${t(isSomeoneElse ? 'common:their' : 'common:your')} ${t(
              'common:form.country.label',
            ).toLowerCase()}`}
          />
          <Input
            className={style.input}
            error={isInputError(errorFields, 'additionalAddress')}
            register={register('additionalAddress')}
            label={t('common:form.additionalAddress.label')}
            placeholder={t('common:form.additionalAddress.placeholder')}
          />
          <Input
            className={style.input}
            error={isInputError(errorFields, 'email')}
            register={register('email', { required: true })}
            label={t('common:form.email.label')}
            placeholder={`${t(isSomeoneElse ? 'common:their' : 'common:your')} ${t('common:form.email.placeholder')}`}
            readOnly={!isSomeoneElse}
            suffix={!isSomeoneElse && <Lock />}
          />
          <Input
            className={style.input}
            error={isInputError(errorFields, 'phone')}
            register={register('phone', { required: true })}
            label={t('common:form.phone.label')}
            placeholder={`${t(isSomeoneElse ? 'common:their' : 'common:your')} ${t(
              'common:form.phone.placeholder',
            ).toLowerCase()}`}
          />
        </div>

        <Card
          className={style.contract}
          inactive={contract.cancelation}
          content={contract.rateType.dashboardPackageDescription || contract.contractType.dashboardDescription}
          onClick={() =>
            navigate(`/contracts/${contract._id}`, {
              state: { fromClaim: true, step: 2 },
            })
          }
          title={
            contract.rateType?.dashboardPackageName 
            ? `${contract.rateType.dashboardPackageName} ${contract.idTransaction}` 
            : `${contract.contractType.dashboardName} ${contract.idTransaction}`
          }
          titleIcon={<DocumentText />}
          cornerContent={<Arrow />}
        />

        <Button
          className={style.button}
          label={t('claim:submit')}
          onClick={handleSubmit}
          type="primary"
          disabled={!isValid}
        />
      </form>
    </>
  )
}

export default ContactForm
